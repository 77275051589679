import { useEffect, useState, useCallback } from "react"
import { useSelector } from "react-redux"
import moment from "moment"


import Button from "../button/Button"
import Loader from "common/src/components/Loader"
import { ReactComponent as IconClicks } from "common/src/svg/click.svg"
import { ReactComponent as IconCommApproved } from "common/src/svg/commission-approved.svg"
import { ReactComponent as IconCommPaid } from "common/src/svg/commission-paid.svg"
import { ReactComponent as IconConnections } from "common/src/svg/connections.svg"

import formatCurrency from "common/src/lib/format/currency"
import api from "app/api"
import useDictRef from "common/src/hooks/useDictRef"

const period2dates = (period) => {
    switch (period) {
        case "last7": {
            return {
                start: moment().subtract(7, "days").toDate(),
                end: moment().toDate()
            }
        }
        case "last30": {
            return {
                start: moment().subtract(30, "days").toDate(),
                end: moment().toDate()
            }
        }
        case "month": {
            return {
                start: moment().startOf("month").toDate(),
                end: moment().endOf("month").toDate()
            }
        }
        default: {
            return null;
        }
    }
}

const period2label = {
    "last7": "7 days",
    "last30": "30 days",
    "month": "This month",
    "total": "Total"
}

function ProfileAnalytics() {

    const currency = useSelector(s => s.user.geo?.currency || "GBP");
    const [ loading, setLoading ] = useState(false);
    const connections = useSelector(s => s.connections.data.connections.length);
    const [ period, setPeriod ] = useState("last7");
    
    const [ commission, setCommission ] = useState(() => formatCurrency(0, currency || "usd", 2));
    const [ approvedCommission, setApprovedCommission ] = 
        useState(() => formatCurrency(0, currency || "usd", 2));
    const [ paidCommission, setPaidCommission ] = 
        useState(() => formatCurrency(0, currency || "usd", 2));
    const [ clicks, setClicks ] = useState(0);

    const ref = useDictRef({ currency, period });

    const loadStats = useCallback(
        async () => {

            const currency = ref.currency.toLowerCase();
            const dates = period2dates(ref.period);
            const clicksRes = await api.reporting.post("/report/my/clicks/byreceiver", {
                body: {
                    ...dates
                }
            });
            const clicks = clicksRes.rows[0]?.cnt || 0;
            
            const ordersRes = await api.reporting.post("/report/my/orders/byreceiver", {
                body: {
                    targetCurrency: currency,
                    ...dates
                }
            });
            const orders = ordersRes.rows[0] || {};

            const approvedRes = await api.reporting.post("/report/my/orders/byreceiver", {
                body: {
                    approved: true,
                    targetCurrency: currency,
                    ...dates
                }
            });
            const approved = approvedRes.rows[0] || {};

            const paidRes = await api.reporting.post("/report/my/orders/byreceiver", {
                body: {
                    approved: true,
                    paid: true,
                    withPaid: true,
                    targetCurrency: currency,
                    ...dates
                }
            });
            const paid = paidRes.rows[0] || {};

            setClicks(clicks);
            setCommission(
                formatCurrency(
                    (orders.grossCommissionAmount || 0) * ref.pseCommission, 
                    orders.currency || currency, 
                    2
                )
            );
            setApprovedCommission(
                formatCurrency(
                    (approved.commissionAmount || 0) * ref.pseCommission, 
                    approved.currency || currency, 
                    2
                )
            );
            setPaidCommission(
                formatCurrency(
                    paid.paidAmount || 0, 
                    approved.currency || currency, 
                    2
                )
            );
            
            //setOrders(orders.cnt || 0);
            //setTotal(formatCurrency(orders.saleAmount || 0, orders.currency || currency, 2));
            //setSold(orders.itemsCount || 0);
        },
        []
    );

    const loadAll = useCallback(
        async () => {
            setLoading(true);
            await loadStats();
            setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const onPeriodChange = useCallback(
        (period) => {
            setPeriod(period);
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => { loadAll() },
        // eslint-disable-next-line
        [ period, currency ]
    )

    return (
        <div className={ "user-profile-analytics " + (loading ? "loading" : "") }>
            <label className="user-profile-title">
                Earnings
                { loading ? <Loader inline size={ 16 }/> : null }
            </label>

            <div className="user-profile-analytics-periods">
                <Button
                    disabled={ loading }
                    size="small"
                    className={ period === "last7" ? "ant-btn-active" : "" }
                    onClick={ () => onPeriodChange("last7") }>
                    { period2label["last7"] }
                </Button>
                <Button 
                    disabled={ loading }
                    size="small"
                    className={ period === "last30" ? "ant-btn-active" : "" }
                    onClick={ () => onPeriodChange("last30") }>
                    { period2label["last30"] }
                </Button>
                <Button 
                    disabled={ loading }    
                    size="small"
                    className={ period === "month" ? "ant-btn-active" : "" }
                    onClick={ () => onPeriodChange("month") }>
                    { period2label["month"] }
                </Button>
                <Button
                    disabled={ loading }
                    size="small"
                    className={ period === "total" ? "ant-btn-active" : "" }
                    onClick={ () => onPeriodChange("total") }>
                    { period2label["total"] }
                </Button>
            </div>

            <div className="user-profile-analytics-total">
                <p>{ commission }</p>
            </div>

            <label>Analytics</label>
            <div className="user-profile-analytics-stats">
                <IconClicks/>
                <p className="user-profile-analytics-label">Clicks</p>
                <p>{ clicks }</p>
            </div>
            <div className="user-profile-analytics-stats">
                <IconCommApproved/>
                <div>
                    <p className="user-profile-analytics-label">Approved Commission</p>
                    <p>This has been approved by the affiliate.</p>
                </div>
                <p>{ approvedCommission }</p>
            </div>
            <div className="user-profile-analytics-stats">
                <IconCommPaid/>
                <div>
                    <p className="user-profile-analytics-label">Paid Commission</p>
                    <p>This is commission that has been paid to you by THE FLOORR</p>
                </div>
                <p>{ paidCommission }</p>
            </div>

            <label>Connections</label>
            <div className="user-profile-analytics-stats">
                <IconConnections/>
                <p className="user-profile-analytics-label">Connections</p>
                <p>{ connections }</p>
            </div>
        </div>
    )
}

export default ProfileAnalytics