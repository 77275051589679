import api from "common/src/api"
import entities from "common/src/api/hasura/entities"

//import "common/src/api/entity/tags"
//import "common/src/api/entity/notifications"


const User = `
    id
    givenName
    familyName
    avatar
    handle
    email
    phone
    groups
    bio
    details
    enabled
    userTerms
    friTerms
    createdAt
`


export const Consultation = `
    id
    indexNo
    title
    contacts
    description
    due
    sizes
    status
    subStatus
    hidden
    createdAt
    updatedAt
    customerId
    friId
    deletionRequest
    customer {
        id
        givenName
        familyName
        phone
        email
        avatar
        contacts {
            type
            value
            normalized
        }
    }
    lastMessage: chatMessages(limit: 1, order_by: { createdAt: desc }) {
        ${ entities.Chat_Message.list }
    }
    fri { ${ User } }
    looks(where: { deleted: { _eq: false }}) { ${ entities.Look.list } }
    images(order_by: { position: asc }) {
        id
        comment
        image
    }
    products {
        id
        sizes
        status
        purchasedAt
        fri { ${ User } }
        product {
            id
            createdAt
            description
            salePrice
            price
            currency
            image
            images
            url
            availability
            sizes
            retailer
            catalogueId
            productStyles { productStyle { ${ entities.ProductStyle.list } } }
            designers { designer { ${ entities.Designer.list } } }
        }
    }
    paymentDetailsId
    paymentDetails {
        paymentDetails
        billingAddress
        shippingAddress
    }
`;



export const ConsultationDraft = `
    createdAt
    data
    id
    userId
`
const User_RecentlyDeleted = `
    userId
    deletedAt
    consultationId
    lookId
    moodboardId
`

const Moodboard = `
    id 
    friId
    name
    image
    public
    pseTemplate
    deleted
    catalogueProducts_aggregate {
        aggregate {
            count
        }
    }
    catalogueProducts(limit: 5, order_by: {createdAt: desc}) {
        catalogueProduct {
            images
        }
    }
    hideMoodboards {
        userId
    }
`;

const Moodboard_CatalogueProduct = `
    id
    userId
    catalogueProductId
    createdAt
    moodboardId
    position
`;

const Moodboard_Share = `
    id
    moodboardId
    userId
    createdAt
`;

/*const Moodboard_Product = `
    id
    moodboardId
    name
    image
    url
    retailer
    productWebId
    brand
    price
    salePrice
    currency
`;*/

const Look = `
    ${ entities.Look.list }
    published
    submitted
    submissionStage
`;

entities.User.list = User;
entities.Consultation.list = Consultation;

Object.assign(entities, 
{

    Look: { list: Look, create: true, update: true, remove: true },
    Product: { list: entities.Product.list, create: true, update: true, remove: true },
    Moodboard: { list: Moodboard, create: true, update: true, remove: true },
    Moodboard_CatalogueProduct: { list: Moodboard_CatalogueProduct, create: true, update: true, remove: true },
    Moodboard_Share: { list: Moodboard_Share, create: true, remove: true },
    User_HideMoodboard: { create: true },
    ConsultationDraft: { list: ConsultationDraft, create: true, update: true, remove: true },
    User_RecentlyDeleted: { list: User_RecentlyDeleted }
    //Moodboard_Product: { list: Moodboard_Product, create: true, update: true, remove: true },

});

export const init = () => {
    api.init();
}

export default api
