import api from "app/api"
import user from "common/src/user"
import hub from "common/src/hub"
import { createProducts } from "common/src/actions/catalogueLocal"
import upload from "common/src/lib/image/upload"
import s3url from "common/src/lib/image/s3url"
import Chat from "common/src/lib/chat/Chat"


export const addProducts = async (id, products) => {

    const pwids = products.map(p => typeof p === "string" ? p : p.productWebId || p.product_web_id || p.catalogueId);
    const cpids = await createProducts(pwids);

    const where = {
        moodboardId: { _eq: id },
        catalogueProductId: { _in: cpids }
    }
    const ex = await api.moodboardCatalogueProduct.list({ where }, "id catalogueProductId")
                        .then(list => list.map(p => p.catalogueProductId));

    for (let i = 0, l = cpids.length; i < l; i++) {
        const position = parseInt(("" + (new Date()).getTime()).substring(4));
        if (ex.indexOf(cpids[i]) !== -1) {
            continue;
        }
        await api.moodboardCatalogueProduct.create({
            moodboardId: id,
            catalogueProductId: cpids[i],
            userId: user.id(),
            position
        })
    }

    hub.dispatch("moodboard", "products-added", { id, productIds: cpids });
};

export const addProduct = async (id, product) => {
    await addProducts(id, [product]);
};

export const removeProducts = async (id, productIds) => {

    for (let i = 0, l = productIds.length; i < l; i++) {
        const pid = productIds[i];
        const where = { 
            moodboardId: { _eq: id }, 
            catalogueProductId: { _eq: pid },
            userId: { _eq: user.id() }
        }
        await api.moodboardCatalogueProduct.remove(where);
    }

    hub.dispatch("moodboard", "products-removed", { id, productIds });
};

const saveImage = async (image, cropCoords) => {
    if (!image) {
        return null;
    }
    if (!image.localId) {
        return image;
    }
    const w = image.width;
    const h = image.height;
    const uploadKey = await upload(image.file || image);

    const payload = {
        uploadKey, 
        contentType: image.mime,
        name: image.name
    }

    if (cropCoords) {
        payload.crop = cropCoords;
    }

    //console.log(payload);

    const imgResponse = await api.backend.post("/upload/moodboard", { body: payload });
    const key = imgResponse.key;

    return {
        key,
        src: s3url(key),
        width: w,
        height: h,
        vertical: h > w,
        horizontal: h < w,
        square: h === w
    }
}

export const create = async (name, image, cropCoords, isPublic = false) => {

    image = await saveImage(image, cropCoords);

    const res = await api.moodboard.create({
        name,
        image: image ? JSON.stringify(image) : null,
        friId: user.id(),
        public: isPublic
    });

    hub.dispatch("moodboard", "created", { id: res.id, name });

    return res;
}

export const update = async (id, name, image, cropCoords, isPublic = false) => {

    image = await saveImage(image, cropCoords);

    await api.moodboard.update(id, {
        name,
        image: image ? JSON.stringify(image) : null,
        public: isPublic
    });

    hub.dispatch("moodboard", "updated", { id, name });
}

export const updatePseTemplate = async (id, pseTemplate = false) => {
    if (user.is("Admin")) {
        await api.moodboard.update(id, {
            pseTemplate
        });
        hub.dispatch("moodboard", "updated", { id });
    }
}

export const remove = async (id) => {

    await api.moodboard.update(id, { deleted: true, deletedAt: (new Date()).toISOString() });

    hub.dispatch("moodboard", "removed", { id });
}

export const moveProducts = async (fromId, toId, productIds, action="copy") => {

    const userId = user.id();
    const where = {
        moodboardId: { _eq: toId },
        catalogueProductId: { _in: productIds }
    }
    const ex = await api.moodboardCatalogueProduct.list({ where }, "id catalogueProductId")
                        .then(list => list.map(p => p.catalogueProductId));

    for (let i = 0, l = productIds.length; i < l; i++) {
        const position = parseInt(("" + (new Date()).getTime()).substring(4));
        if (ex.indexOf(productIds[i]) !== -1) {
            continue;
        }
        await api.moodboardCatalogueProduct.create({
            moodboardId: toId,
            catalogueProductId: productIds[i],
            userId,
            position
        })
    }

    if (action === "move") {
        for (i = 0, l = productIds.length; i < l; i++) {
            await api.moodboardCatalogueProduct.remove({
                moodboardId: fromId,
                catalogueProductId: productIds[i],
                userId
            })
        }
    }

    hub.dispatch("moodboard", "products-added", { id: toId, productIds });

};

export const shareMoodboard = async (moodboardId, toUserId) => {
    
}

export const sendMoodboard = async (moodboardId, toUserId) => {

    const chat = new Chat({ detached: true, currentUserRole: "shopper" });
    const contact = await api.user.get(toUserId);
    const moodboard = await api.moodboard.get(moodboardId);
    await chat.setContact(contact);

    chat.addAttachment("moodboard", moodboard, moodboard.id);
    await chat.send();

}

export const hideMoodboard = async (id) => {

    try {
        await api.userHideMoodboard.create({
            moodboardId: id,
            userId: user.id()
        })
    }
    catch (err) {
        console.error(err);
    }

    hub.dispatch("moodboard", "removed", { id });
}


export const rearrange = async (moodboardId, sourceProduct, targetProduct) => {

    await api.backend.post("/moodboard/rearrange", { body: {
        moodboardId,
        productId: sourceProduct.id,
        targetProductId: targetProduct.id
    }});

    hub.dispatch("moodboard", "rearrange", { id: moodboardId });
}