import { useMemo } from "react"

import Select from "common/src/refactor/components/ant/Select"
import user from "common/src/user"
import { useSelector } from "react-redux"
import { useCallback } from "react"


const filterRetailers = (list, checkUserAccess) => {

    let details, userRetailers, allRetailers;

    if (checkUserAccess) {
        details = user.getDetails();
        userRetailers = [ ...(details.retailers || []) ];
        allRetailers = userRetailers.indexOf("all") !== -1;
    }

    return list.filter(r => {
        if (r.visible === false) {
            return false;
        }
        if (checkUserAccess) {
            return allRetailers || userRetailers.indexOf(r.id) !== -1;
        }
        return true;
    });
}

export function RetailerSelect({ checkUserAccess = false, className, ...props }) {

    const retailersList = useSelector(s => s.catalogue.data.retailers);

    const options = useMemo(
        () => {
            const retailers = filterRetailers(retailersList, checkUserAccess);
            return [
                {
                    label: <div className="selector-retailer-header"><span>Retailer</span>  <span>Commission</span></div>,
                    title: "Retailer",
                    options: retailers.map(r => {
                        return {
                            key: r.id,
                            label: r.label,
                            value: r.id,
                            commission: r.commissionRate
                        }
                    })
                }
            ]
        },
        [ checkUserAccess, retailersList ]
    );

    const optionRender = useCallback(
        (option) => {
            return (
                <div className="selector-retailer-option" key={ option.data.key }>
                { option.data.label } 
                    <div className="selector-retailer-commission">
                     Up to <span>{ option.data.commission }</span>%
                    </div>
                </div>)
        },
        []
    )

    const cls = useMemo(
        () => [ "selector-retailer", className || "" ].join(" "),
        [ className ]
    );

    return (
        <Select 
            className={ cls }
            placeholder="Retailer"
            options={ options } 
            optionRender={ optionRender }
            showSearch
            popupMatchSelectWidth={ false }
            maxTagCount="responsive"
            { ...props }/>
    )
}
