import { useSelector } from "react-redux"
import { useCallback, useMemo, useState } from "react"
import { Drawer, Input, App as AntApp } from "antd"
import parsePhoneNumber from 'libphonenumber-js'

import Button from "../button/Button"
import FormInput from "common/src/refactor/components/form/FormInput"
import NullForm from "common/src/components/NullForm"

import { Form, useFormFields } from "common/src/refactor/lib/form/Form"
import { ui } from "common/src/store/dialogs"
import store from "app/store"
import required from "common/src/refactor/lib/form/validator/required"
import useDictRef from "common/src/hooks/useDictRef"
import userSelector from "common/src/selectors/user/current"
import isEmail from "common/src/refactor/lib/form/validator/isEmail"
import isPhoneNumber from "common/src/refactor/lib/form/validator/isPhoneNumber"
import * as actions from "common/src/actions/user"
import { getDefaultEmailContact, getDefaultPhoneContact, syncContacts } 
    from "common/src/actions/contacts"
import user from "common/src/user"

async function validateUniqueEmail(email) {
    const res = await actions.checkEmail(email);
    return !res;
}

function validatePhoneNumber(value) {
    if (!value) {
        return true;
    }
    return isPhoneNumber(value);
}

const DIALOG_NAME = "edit-user-details";

const fields = [
    {
        name: "givenName",
        validator: [
            [ required, "Please enter your name" ]
        ]
    },
    {
        name: "familyName",
        validator: [
            [ required, "Please enter your last name" ]
        ]
    },
    {
        name: "email",
        validator: [
            [ required, "Please enter your email" ],
            [ isEmail, "Please enter a valid email" ],
            [ validateUniqueEmail, "Sorry, you can't use this email" ]
        ]
    },
    {
        name: "phone",
        validator: [
            [ validatePhoneNumber, "Please enter a valid phone number" ]
        ]
    },
    {
        name: "bio"
    }
]

function EditUserDetailsForm() {

    const { message } = AntApp.useApp();
    const current = useSelector(userSelector);
    const contacts = current.contacts;
    const currentPhone = contacts.find(c => c.type === "phone") || getDefaultPhoneContact(current);
    const currentEmail = contacts.find(c => c.type === "email") || getDefaultEmailContact(current);
    const [ saving, setSaving ] = useState(false);
    const form = useMemo(
        () => new Form(fields, {
            givenName: current?.givenName,
            familyName: current?.familyName,
            email: current?.email,
            phone: current?.phone,
            bio: current?.bio || ""
        }), 
        []
    );
    const {
        givenName, givenNameError, givenNameChange,
        familyName, familyNameError, familyNameChange,
        email, emailError, emailChange,
        phone, phoneError, phoneChange,
        bio, bioError, bioChange
    } = useFormFields(["givenName", "familyName", "email", "phone", "bio"], form);

    const ref = useDictRef({ givenName, familyName, email, phone, bio });

    const onSubmitClick = useCallback(
        async () => {
            setSaving(true);

            const valid = await form.validateAll();

            if (!valid) {
                setSaving(false);
                return;
            }

            const emailContact = {
                ...currentEmail,
                value: ref.email.trim(),
                normalized: ref.email.trim().toLowerCase()
            };

            const contacts = [ emailContact ];

            const payload = { 
                givenName: ref.givenName, 
                familyName: ref.familyName, 
                email: ref.email, 
                bio: ref.bio,
                phone: ""
            };

            if (ref.phone) {
                const parsedPhone = parsePhoneNumber(ref.phone);
                if (parsedPhone) {
                    const dialCode = parsedPhone.countryCallingCode;
                    const phoneNumber = parsedPhone.nationalNumber;
                    const phoneContact = {
                        ...currentPhone,
                        value: `+${ dialCode } ${ phoneNumber }`,
                        normalized: `+${ dialCode }${ phoneNumber.replace(/[^0-9]/g, "") }`
                    };
                    contacts.push(phoneContact);
                    payload.phone = phoneContact.normalized;
                }
            }

            await syncContacts(contacts, user.id());
            await actions.update(payload)
                .then(() => {


                    

                    message.success({
                        content: "Your details are now updated.",
                        icon: <></>,
                    })
                    /*alert({
                        title: "Your details have been updated.",
                        message: "Thanks for keeping your details on THE FLOORR up to date."
                    });*/
                    EditUserDetailsDialog.hide();
                })
                .catch(err => {
                    message.error({
                        content: err.message
                    })
                    /*alert({
                        title: "User update failed",
                        message: err.message
                    })*/
                })

            setSaving(false);
        },
        [ currentPhone, currentEmail ]
    );

    return (
        <NullForm className="edit-user-details-form">
            <FormInput error={ givenNameError } label="Given name *">
                <Input 
                    disabled={ saving }
                    value={ givenName } 
                    onChange={ givenNameChange }
                    onPressEnter={ onSubmitClick }/>
            </FormInput>

            <FormInput error={ familyNameError } label="Last name *">
                <Input 
                    disabled={ saving }
                    value={ familyName } 
                    onChange={ familyNameChange }
                    onPressEnter={ onSubmitClick }/>
            </FormInput>

            <FormInput error={ emailError } label="Email *">
                <Input 
                    disabled={ saving }
                    value={ email } 
                    onChange={ emailChange }
                    onPressEnter={ onSubmitClick }/>
            </FormInput>

            <FormInput error={ phoneError } label="Phone number (+12345678)">
                <Input 
                    disabled={ saving }
                    value={ phone } 
                    onChange={ phoneChange }
                    onPressEnter={ onSubmitClick }/>
            </FormInput>

            <FormInput error={ bioError } label="Bio">
                <Input.TextArea
                    disabled={ saving }
                    value={ bio } 
                    onChange={ bioChange }
                    onPressEnter={ onSubmitClick }/>
            </FormInput>

            <Button 
                disabled={ saving }
                loading={ saving }
                type="primary" 
                text="Save"
                onClick={ onSubmitClick }/>
        </NullForm>
    )
}

function EditUserDetailsDialog() {

    const open = useSelector(s => s.dialogs[DIALOG_NAME]);

    const onClose = useCallback(
        () => { EditUserDetailsDialog.hide() },
        []
    );

    return (
        <Drawer 
            className="tf-drawer edit-user-details-drawer"
            title="Details"
            open={ open } 
            onClose={ onClose }
            destroyOnClose>
            { open && <EditUserDetailsForm/> }
        </Drawer>
    )
}

EditUserDetailsDialog.show = function() {
    store.dispatch(ui.show(DIALOG_NAME));
}

EditUserDetailsDialog.hide = function() {
    store.dispatch(ui.hide(DIALOG_NAME));
}


export default EditUserDetailsDialog