import { useState, useCallback } from "react"
import { Input, Spin } from "antd"
//import Icon from "@ant-design/icons"
import InfiniteScroll from "react-infinite-scroll-component"

import Button from "common/src/refactor/components/button/Button"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"
import Page from "common/src/refactor/components/Page"
import Spacer from "common/src/refactor/components/Spacer"
import MoodboardCard from "common/src/refactor/components/moodboard/Card"
import MoodboardDrawer from "common/src/refactor/components/moodboard/Drawer"

import { ReactComponent as IconSearch } from "common/src/svg/search.svg"

import useDualState from "common/src/refactor/hooks/useDualState"
import useQuery from "common/src/refactor/hooks/useQuery"
import { loadMoodboards } from "common/src/actions/moodboards"
import useInputHander from "common/src/refactor/hooks/userInputHandler"
import MoodboardContextMenu from "app/components/moodboard/ContextMenu"

const params = [
    {
        name: "query",
        default: ""
    }
];

const PER_PAGE = 16;


function PageMoodboards() {

    const [ page, setPage ] = useState(0);
    const { query, setQuery, all, applySearchParams } = useDualState({
        params,
        mode: "both"
    });

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            setPage(0);
            if (source === "clear") {
                setQuery("");
            }
            applySearchParams();
        },
        [ applySearchParams, setQuery ]
    );

    const { data: moodboards, 
            isLoading, 
            isLoaded, 
            refetch,
            extraData: { hasMore } } = 
        useQuery(
            loadMoodboards,
            [ all, page ],
            {
                params: {
                    page,
                    perPage: PER_PAGE,
                    ...all
                },
                rowKey: "id",
                append: page > 0,
                processResponse: (response) => {
                    return { 
                        data: response.items, 
                        count: response.count,
                        hasMore: response.items.length >= PER_PAGE
                    }
                },
                initialData: []
            }
    );

    const loadMore = useCallback(
        () => setPage(page => page + 1),
        []
    );

    const onCreateClick = useCallback(
        () => {
            MoodboardDrawer.show().then(m => {
                if (m) {
                    refetch();
                }
            });
        },
        [ refetch ]
    );

    return (
        <Page className="page-moodboards" loading={ isLoading && moodboards.length === 0 }>
            <Spacer size="xlarge"/>
            <div className="toolbar">
                <Input.Search 
                    size="large"
                    placeholder="Search moodboards"
                    enterButton={
                        <Button 
                            Component="a"
                            onClick={ e => e.preventDefault() }
                            Icon={ IconSearch }
                            text="Search" 
                            type="primary"/>
                    }
                    allowClear
                    onChange={ handleQueryChange }
                    value={ query }
                    onSearch={ onSearch }/>
                <Button
                    type="primary"
                    text="Create moodboard"
                    onClick={ onCreateClick }/>
            </div>
            <Spacer size="xlarge"/>
            <InfiniteScroll 
                className="page-moodboards-scroller page-feed-scroller-full-page"
                dataLength={ moodboards.length }
                hasMore={ hasMore }
                next={ loadMore }
                loader={ moodboards.length > 0 ? 
                            <Spin spinning className="infinite-scroll-spinner"/> : 
                            null }>
                { (isLoaded && !isLoading && moodboards.length === 0) && <EmptyMessage message="No moodboards matched your search" size="small" /> }
                <div className="moodboard-cards">
                    { moodboards.map(m => (
                        <MoodboardCard 
                            key={ m.id } 
                            moodboard={ m } 
                            onClick="default">
                            <MoodboardContextMenu moodboard={ m } onEdit={ refetch } onDelete={ refetch }/>
                            </MoodboardCard>
                    ))}
                </div>
            </InfiniteScroll>
        </Page>
    )
}

export default PageMoodboards