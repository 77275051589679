//import { useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { Tooltip } from "antd"

//import Loader from "common/src/components/Loader"

import { ReactComponent as IconTick } from "common/src/svg/tick.svg"
import VerifyEmailDialog from "./VerifyEmailDialog"
import VerifyPhoneDialog from "./VerifyPhoneDialog"

//import { ui as ddUi } from "common/src/store/dialogs"
//import { ui } from "common/src/store/user"
import df from "common/src/lib/date/formats"
import userSelector from "common/src/selectors/user/current"
//import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
//import { resendEmailVerification, checkCurrent } from "common/src/actions/user"
//import { getDefaultEmailContact, getDefaultPhoneContact } from "common/src/actions/contacts"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"


function ProfileDetails() {

    //const dispatch = useDispatch();
    const current = useSelector(userSelector);
    //const [ resendingEmail, setResendingEmail ] = useState(false);
    //const [ verificationSent, setVerificationSent ] = useState(false);
    const { //contacts = [],
            givenName, 
            familyName, 
            emailVerified, 
            phoneVerified,
            email,
            phone,
            createdAt } = current;
    const canVerify = phone && phone.indexOf("+1") !== 0;
    //const phone = contacts.find(c => c.type === "phone") || getDefaultPhoneContact(current);
    //const email = contacts.find(c => c.type === "email") || getDefaultEmailContact(current);     

    const onVerifyEmailClick = useSwallowEventCallback(
        () => {
            VerifyEmailDialog.show();
        },
        []
    );

    const onVerifyPhoneClick = useSwallowEventCallback(
        () => {
            VerifyPhoneDialog.show();
        },
        []
    )

    return (
        <div className="user-profile-details">
            <label className="user-profile-title">Your details</label>
            <h2>{ givenName } { familyName }</h2>
            
            <p className="user-profile-email">
                { email }
                { emailVerified ?
                    <Tooltip title="Email verified">
                        <span className="user-profile-verified"><IconTick/></span>
                    </Tooltip> :
                    <a href="/#" onClick={ onVerifyEmailClick }>Verify email</a> }
            </p>

            { !!phone && 
                <p className="user-profile-phone">
                    { phone }
                    { canVerify ? 
                        phoneVerified ?
                            <Tooltip title="Phone number verified">
                                <span className="user-profile-verified"><IconTick/></span>
                            </Tooltip> :
                            <a href="/#" onClick={ onVerifyPhoneClick }>Verify phone</a> :
                        null }
                </p> }
            <p className="user-profile-signedup">
                <label>Signed up</label>
                <span>{ moment(createdAt).format(df.full) }</span>
            </p>
            <VerifyEmailDialog/>
            <VerifyPhoneDialog/>
        </div>
    )

}

export default ProfileDetails