import * as actions from "common/src/actions/moodboard"
import { App as AntApp } from "antd"
import ConnectionSelector from "common/src/refactor/components/dialog/ConnectionSelector";
import { useCallback, useState } from "react"
import MoodboardDrawer from "common/src/refactor/components/moodboard/Drawer";
import user from "common/src/user"

function useMoodboardActions({ moodboard, onDelete, onEdit }) {

    const { message, modal } = AntApp.useApp();
    const [ sending, setSending ] = useState(false);
    const [ editing, setEditing ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    
    const onSendClick = useCallback(
        async () => {
            const conns = await ConnectionSelector.show({
                multiple: true
            });

            setSending(true);

            for (let i = 0; i < conns.length; i++) {
                await actions.sendMoodboard(moodboard.id, conns[i].id);
            }

            if (conns.length === 1) {
                message.success({
                    content: `Moodboard sent to ${ conns[0].givenName } ${ conns[0].familyName }`,
                    icon: <></>
                });
            }
            else {
                const names = conns.map(c => `${ c.givenName } ${ c.familyName }`).join(", ");

                message.success({
                    content: `Moodboard sent to ${ names }`,
                    icon: <></>
                });
            }

            setSending(false);

        }, 
        [ moodboard, message ]
    );

    const deleteMoodboard = useCallback(
        async () => {
            setDeleting(true);
            await actions.remove(moodboard.id);

            message.success({
                content: `${ moodboard.name || "Moodboard" } has been deleted`,
                icon: <></>
            });

            setDeleting(false);
            onDelete && onDelete();
        },
        [ moodboard, message, onDelete ]
    );

    const onDeleteClick = useCallback(
        async () => {
            modal.confirm({
                closable: true,
                icon: null,
                width: 540,
                title: "Delete moodboard?",
                content: "You can restore this moodboard in 'Recently Deleted' from your profile within 30 days. After that, it will be permanently deleted.",
                okText: "DELETE NOW",
                cancelButtonProps: { 
                    style: { 
                        display: 'none' 
                    } 
                },
                okButtonProps: { 
                    style: {
                        textTransform: "uppercase"
                    }
                },
                onOk: async () => {
                    await deleteMoodboard();
                }
            })
        },
        [ modal, deleteMoodboard ]
    );

    const onEditClick = useCallback(
        () => {
            setEditing(true);
            if (moodboard) {
                MoodboardDrawer.show({ moodboard }).then(() => {
                    setEditing(false);
                    onEdit && onEdit();
                });
            }
        },
        // eslint-disable-next-line
        [ moodboard, onEdit ]
    );

    const moodboardApi = {
        onEditClick,
        onSendClick,
        onDeleteClick,

        loading: sending || deleting || editing
    }

    if (user.isOnly("Admin")) {
        moodboardApi.onSendClick = null;
    }

    if (moodboard?.friId !== user.id()) {
        moodboardApi.onEditClick = null;
    }

    return moodboardApi;
}

export default useMoodboardActions;