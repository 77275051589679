
import singlePromise from "common/src/lib/js/singlePromise"
import { batch } from "react-redux"
import { data, ui } from "app/store/page/consultations"
import store from "app/store"
import hub from "common/src/hub";
import settings from "app/settings"
import { consultationsLoader } from "common/src/actions/consultations"
import user from "common/src/user"
import entities from "common/src/api/hasura/entities";
import api from "common/src/api";


const PER_PAGE = settings.consultationsPerPage;

let subscription;

function setSubscriptionWhere(where, options = {}) {
        where.friId = { _eq: user.id() };
        where._or = [
            { 
                customerId: { _is_null: true } 
            },
            {            
                customer: {
                    userConnections: {
                        connection: {
                            users: {
                                userId: {
                                    _eq: user.id() 
                                }
                            }
                        }
                    }
                }
            }
        ]

        if (options.activeOnly) {
            where.status = {
                _nin: [ "cancelled", "cancelRequest" ]
            }
        }
}

function load(status, page = 0, onlyMine = true, query = null) {
    const   sortDirection = "desc",
            where = {
                _and: []
            };
    //const   isAdmin = user.is("Admin");

    

    switch (status) {
        // case "all": {
        //     //console.trace()
        //     where._and.push({
        //         friId: { _is_null: true }
        //     })
        //     where.friId = { _is_null: true };
        //     where.status = { _nin: [ "cancelled", "cancelrequest" ]};
        //     break;
        // }
        // case "unassigned": {
        //     where.friId = { _is_null: true };
        //     where.status = { _nin: [ "cancelled", "completed", "cancelrequest" ]};
        //     break;
        // }
        case "all": {
            // where._and.push({
            //     status: { _nin: [ "cancelled", "deleted" ] }
            // });
            break;
        }
        case "inprogress": {
            //where.friId = { _is_null: false };
            //where.status = { _eq: "inprogress" };
            where._and.push({
                status: { _eq: "inprogress" }
            });
            break;
        }
        // case "newinprogress": {
        //     //where.friId = { _is_null: false };
        //     //where.status = { _nin: [ "new", "inprogress" ]};
        //     where._and.push({
        //         status: { _nin: [ "new", "inprogress" ] }
        //     });
        //     break;
        // }
        case "my": {
            //where.status = { _in: [ "new", "inprogress" ] };
            //where.friId = { _eq: user.id() };
            where._and.push({
                status: { _in: [ "new", "inprogress" ] }
            });
            break;
        }
        case "new": {
            //where.friId = { _is_null: false };
            //where.status = { _eq: "new" };
            where._and.push({
                status: { _eq: "new" } 
            });
            break;
        }
        case "cancelrequest": {
            // if (isAdmin) {
            //     where.status = { _eq: "cancelRequest" };
            // }
            // else {
                
            // }
            //where.status = { _in: [ "cancelRequest", "cancelled" ] };
            where._and.push({
                status: { _in: [ "cancelRequest", "cancelled" ] } 
            });
            break;
        }
        case "cancelled": {
            //where.status = { _eq: "cancelled" };
            where._and.push({
                status: { _eq: "cancelled" } 
            });
            break;
        }
        case "completed": {
            //where.status = { _eq: "completed" };
            where._and.push({
                status: { _eq: "completed" } 
            });
            break;
        }
        default: {
            console.trace();
        }
    }

    if (query) {
        where._and.push({
            _or: [
                {
                    title: { _ilike: `%${ query }%` }
                },
                {
                    description: { _ilike: `%${ query }%` }
                }
            ]
        })
        // where._or = [
            
        // ]
    }

    where._and.push({
        friId: { _eq: user.id() }
    })

    where._and.push({
        _or: [
            { 
                customerId: { _is_null: true }
            },
            {
                customer: {
                    userConnections: {
                        connection: {
                            users: {
                                userId: {
                                    _eq: user.id()
                                }
                            }
                        }
                    }
                }
            }
        ]
    })

    // if (!user.is("Admin") || onlyMine) {
    //     if (!where._or) {
    //         where._or = [];
    //     }
        
    //     where.customer = {
    //         userConnections: {
    //             connection: {
    //                 users: {
    //                     userId: {
    //                         _eq: user.id()
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    // if (onlyMine) {
    //     where.friId = { _eq: user.id() };
    // }
    return consultationsLoader({
        where, 
        order: { createdAt: sortDirection }, 
        offset: page * PER_PAGE, 
        limit: null,
        withLooks: false,
        withPublishedLooks: false,
        withCount: page === 0
    });
}

export let reloadList = function(onlyMine) {
    const   state = store.getState(),
            currStatus = state.consultationsPage.ui.list.status,
            page = state.consultationsPage.ui.list.page,
            query = state.consultationsPage.ui.list.search;

    return loadList(currStatus, page, true, onlyMine, query);
}

export let loadList = singlePromise({
    mode: singlePromise.KEY_CANCEL,
    pre: (status, page, force, onlyMine, query) => {
        let state = store.getState(),
            currStatus = state.consultationsPage.ui.list.status,
            currPage = state.consultationsPage.ui.list.page,
            currQuery = state.consultationsPage.ui.list.search;

        if (!status) {
            status = currStatus;
        }

        let setup = {
            key: status,
            status,
            page,
            query,
            onlyMine
        };

        if (currStatus === status && 
            currPage === page &&
            (currQuery || "") === (query || "") && 
            state.consultationsPage.data.list.length > 0) {
            if (!force) {
                setup.value = {
                    items: state.consultationsPage.data.list,
                    count: state.consultationsPage.ui.list.count
                };
                setup.skip = true;
            }
        }
        return setup;
    },
    fn: (status, page, force, onlyMine, query) => {
        store.dispatch(ui.list.loading(true));
        return load(status, page, onlyMine, query);
    },
    resolve: (response, setup, args) => {
        if (setup.page === 0) {
            batch(() => {
                store.dispatch(data.list.set(response.items));
                store.dispatch(ui.list.count(response.count));
                store.dispatch(ui.list.status(setup.status));
                store.dispatch(ui.list.page(setup.page || 0));
                store.dispatch(ui.list.search(setup.query || ""));
            });
        }
        else {
            batch(() => {
                store.dispatch(data.list.set(response));
                store.dispatch(ui.list.page(setup.page || 0));
            });
        }
    },
    reject: err => {
        hub.dispatch("error", "consultation-load-list", err);
    },
    always: () => {
        store.dispatch(ui.list.loading(false));
    }
})

export const subscribeToUserConsultations = async ({
    options = {}
}) => {
    store.dispatch(ui.list.loading(true));
    
    if (subscription) {
        return;
    }
    const graph = entities.Consultation.list;
    const order = { lastUpdateAt: "desc", createdAt: "desc" };
    const limit = 100;
    const where = {};
    
    setSubscriptionWhere(where, options);

    subscription = await api.consultation.subscribeList({ where, order, limit }, graph, async () => {
        await loadList("all", 0, true, true, null);
    })
}

export const unsubscribeFromUserConsultations = () => {
    if (subscription) {
        if (subscription.subscription) {
            subscription.subscription.unsubscribe();
        }
        subscription = null;
    }
}

