import { useCallback, useState, useMemo, useEffect } from "react"

import Select from "common/src/refactor/components/ant/Select"

import api from "app/api"
import useDictRef from "common/src/hooks/useDictRef"

const PER_PAGE = 20;

function designerToOption(d) {
    return { ...d, label: d.name, key: d.id, value: d.id }
}

export function DesignerSelect({ className, stage = "live", ...props }) {

    const [ loading, setLoading ] = useState(false);
    const [ options, setOptions ] = useState([]);
    const [ start, setStart ] = useState(0);
    const [ hasNext, setHasNext ] = useState(true);
    const [ query, setQuery ] = useState("");

    const ref = useDictRef({ options, query, stage, loading, hasNext, start })

    const loadDesigners = useCallback(
        async (append = false) => {
            ref.loading = true;
            setLoading(true);

            let options = [];
            let { designers } = await api.unauth.post("/catalogue/designers", { body: {
                start: ref.start,
                perPage: PER_PAGE,
                query: ref.query,
                stage: ref.stage
            }});

            if (append) {
                options = [ ...ref.options, ...designers.map(designerToOption) ];
            }
            else {
                options = designers.map(designerToOption);
            }

            setOptions(options);
            setLoading(false);
            setHasNext(designers.length === PER_PAGE);
            ref.loading = false;
        },
        []
    );

    const loadMore = useCallback(
        () => {
            setStart(ref.start + PER_PAGE);
            ref.start = ref.start + PER_PAGE;
            loadDesigners(true);
        },
        []
    );

    const onSearch = useCallback(
        (query) => {
            setQuery(query);
            setStart(0);
            ref.start = 0;
            ref.query = query;
            loadDesigners();
        },
        []
    );

    useEffect(
        () => {
            loadDesigners();
        },
        []
    );

    const onPopupScroll = useCallback(
        (e) => {
            const el = e.target;
            if (ref.hasNext && !ref.loading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    loadMore();
                }
            }
        },
        []
    );

    const cls = useMemo(
        () => [ "selector-designer", className || "" ].join(" "),
        [ className ]
    );

    return (
        <Select 
            className={ cls }
            showSearch
            loading={ loading }
            placeholder="Designer"
            options={ options } 
            filterOption={ false }
            maxTagCount="responsive"
            onPopupScroll={ onPopupScroll }
            searchValue={ query }
            onSearch={ onSearch }
            { ...props }/>
    )
}

