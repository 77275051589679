import { useCallback, useEffect, useState } from "react"
import useDictRef from "common/src/hooks/useDictRef";

const defaultDeps = [ false ];

function useQuery(fn, deps = defaultDeps, options = {}) {

    const { append, prepend, params, rowIdKey, map, 
            prepareParams, processResponse,
            prepare, onLoad, enabled = true } = options;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ data, setData ] = useState(options.initialData !== undefined ? 
                                            options.initialData : 
                                            []);
    const [ error, setError ] = useState(null);
    const [ count, setCount ] = useState(options.initialData ? options.initialData.length : 0);
    const [ extraData, setExtraData ] = useState({});
    const ref = useDictRef({ 
        fn, append, prepend, params, prepareParams, processResponse,
        rowIdKey, map, prepare, onLoad, enabled });

    const prepareData = useCallback(
        (prev, data) => {
            if (ref.prepare) {
                data = ref.prepare(data);
            }
            if (ref.map) {
                data = data.map(ref.map);
            }
            if (ref.append) {
                data = [ ...prev, ...data ];
            }
            else if (ref.prepend) {
                data = [ ...data, ...prev ];
            }
            if (ref.rowIdKey && prev && prev.length > 0) {
                data = data
                        .reverse()
                        .filter((r,inx,self) => 
                                typeof ref.rowIdKey === "string" ?
                                    self.findIndex(r1=>r1[ref.rowIdKey] === r[ref.rowIdKey]) === inx :
                                    self.findIndex(r1=>ref.rowIdKey(r1) === ref.rowIdKey(r)) === inx
                        )
                        .reverse();
            }
            ref.onLoad && ref.onLoad(data);
            return data;
        },
        [ ref ]
    );

    const refetch = useCallback(
        async (params) => {
            if (!ref.enabled) {
                return;
            }
            setIsLoading(true);
            let finalParams = { ...ref.params, ...params };
            if (ref.prepareParams) {
                finalParams = await ref.prepareParams(finalParams);
            }
            if (finalParams === false) {
                setData([]);
                setIsLoading(false);
                setExtraData({});
                setError(null);
                setCount(0);
            }
            else {
                setIsLoaded(false);
                const response = await ref.fn(finalParams);
                const { data, error, count, ...rest } = ref.processResponse ? 
                                                            ref.processResponse(response) : 
                                                            response;
                setData(prev => prepareData(prev, data, finalParams.overwrite || false));
                setIsLoading(false);
                setIsLoaded(true);
                setExtraData(rest || {});
                setError(error);
                if (count !== undefined) {
                    setCount(count);
                }
            }
        },
        [ ref, prepareData ]
    );

    const reset = useCallback(
        () => {
            setData([]);
            setCount(0);
            setError(null);
        },
        []
    );

    useEffect(
        () => {
            refetch();
        },
        // eslint-disable-next-line
        deps
    );

    return {
        data, isLoading, error, refetch, 
        count, reset, extraData, isLoaded,
        setData
    }
}

export default useQuery