
import { defaultRates } from "common/src/lambdalib/userPaymentSettings"


export const catalogueEuroCodes = "AT BE BG HR CY CZ DK EE FI FR DE " +
    "GR HU IE IT LV LT LU MT NL PL " +
    "PT RO SK SI ES SE AL AD AM BY BA FO GE GI IS IM XK LI MK MD " +
    "MC ME NO RU SM RS CH TR UA VA";


function findAffiliateRates(affiliate, affiliates, aliases) {

    if (affiliates[affiliate]) {
        return affiliates[affiliate];
    }

    const als = aliases.find(als => als.indexOf(affiliate) !== -1);

    if (!als) {
        return null;
    }

    for (let i = 0, l = als.length; i < l; i++) {
        const a = als[i];
        if (affiliates[a]) {
            return affiliates[a];
        }
    }

    return null;
}

function findRateByRegion(region, rates) {

    if (!region) {
        return rates.find(r => r.region === "*") ||
            rates.find(r => r.region === "GB") ||
            null;
    }

    let r = rates.find(r => r.region === region);

    if (region === "UK" && !r) {
        r = rates.find(r => r.region === "GB");
    }

    if (r) {
        return r;
    }

    const eu = rates.find(r => r.region === "EU");

    if (eu && catalogueEuroCodes.indexOf(region) !== -1) {
        return eu;
    }

    const all = rates.find(r => r.region === "*");

    return all || null;
}

function findNetworkByRetailer(retailer, networksMap) {
    const networks = Object.keys(networksMap);
    return networks.find(n => !!networksMap[n][retailer]);
}

function calculateCommissionRate(network, affiliate, region, rates, userRate = defaultRates.fri) {

    if (!network && affiliate) {
        if (rates?.networks) {
            network = findNetworkByRetailer(affiliate, rates.networks);
            if (!network) {
                return null;
            }
        }
        else {
            return null;
        }
    }

    const affiliates = rates.networks[network];

    if (!affiliates) {
        return null;
    }

    const affiliateRates = findAffiliateRates(affiliate, affiliates, rates.aliases);

    if (!affiliateRates) {
        return null;
    }

    const regionRate = findRateByRegion(region, affiliateRates);

    if (!regionRate) {
        return null;
    }

    const retailerRate = regionRate.pseCommission / 100;
    const rate = parseInt(regionRate.commission * (retailerRate || userRate));

    return rate;
}

export default calculateCommissionRate;